import Alpine from 'alpinejs';
import Tagify from '@yaireo/tagify';
window.Tagify = Tagify;
export default function () {
   Alpine.store('tagify', {
      editTags: false,
      maxTags: 15,
      closeOnSelect: false,
      enforceWhitelist: true,
      placeHolder: 'Add / Remove Tags',
      dropdown: {
         enforceWhitelist: true,
         closeOnSelect: false,
         position: 'input',
         enabled: 0,
         maxItems: 15
      }
   });
}
