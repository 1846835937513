import selectable from './stores/_selectable';
import sidebar from './stores/_sidebar';
import mobile from './stores/_mobile';
import tagify from './stores/_tagify';
import utils from './stores/_utils';
import state from './stores/_state';
import slider from './stores/_slider';

export default function () {
   document.addEventListener('alpine:init', e => {
      state();
      mobile();
      selectable();
      sidebar();
      tagify();
      utils();
      slider();
   });
}
