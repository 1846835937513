import Splide from '@splidejs/splide';

import Alpine from 'alpinejs';
// $(document).ready(function () {
//    $('.splide').each(function () {
//       var slider = new Splide(this, {
//          arrows: true,
//          pagination: false,
//          heightRatio: 2100 / 1080
//       });

//       slider.on('active', function (e) {
//          let target = $(e.slide).find('[data-media-id]').get(0);
//          let video = $(e.slide).find('video').get(0);
//          Alpine.$data(target).autoplay = false;
//          Alpine.$data(target).playing = true;
//       });
//       slider.on('inactive', function (e) {
//          let target = $(e.slide).find('[data-media-id]').get(0);
//          let video = $(e.slide).find('video').get(0);
//          Alpine.$data(target).autoplay = false;
//          Alpine.$data(target).playing = false;
//       });
//       slider.on('resize', e => {
//          console.log(e);
//       });
//       slider.mount();
//    });
// });
window.Splide = Splide;
