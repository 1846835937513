import Alpine from 'alpinejs';
export default function () {
   Alpine.store('selectable', {
      init () {},
      activate (location = null, groupId = null) {
         this.groupId = groupId;
         this.location = location;
         this.selectable = new Selectable({
            filter: '.selectable',
            container: document.getElementById('thumb-wrap'),
            ignore: '.media-item'
         });
         this.selectable.on('end', () => {
            this.update();
         });
         this.selectable.on('deselect', item => {
            this.deselectHandler(item.node);
         });
         this.selectable.on('select', item => {
            this.selectHandler(item.node);
         });
         Alpine.store('state')._selecting(true, location);
      },
      refresh (groupId = null) {
         this.deselectAll();
         this?.selectable?.destroy();
         this.selectable = null;
         this.groupId = groupId;
         Alpine.store('state')._selecting(false, location);
         Alpine.nextTick(() =>
            this.activate({
               location: 'sidebar',
               groupId: this.groupId
            })
         );
      },
      //# destroy
      destroy (location = null) {
         this.deselectAll();
         this?.selectable?.destroy();
         this.selectable = null;
         this.groupId = null;
         Alpine.store('state')._selecting(false, location);
         Livewire.emitTo('media-library.file-view', 'updateView');
      },
      update () {
         this.selected = this.selectable.getSelectedNodes();
         this.selectedIds = this.selected.map(e => Number(e.dataset.mediaId));
      },
      //# deselectAll
      deselectAll () {
         this.selected.forEach(el => this.deselect(el));
      },
      selectHandler (el) {
         Alpine.$data(el).selected = true;
         this.update();
      },
      deselectHandler (el) {
         Alpine.$data(el).selected = false;
         this.update();
      },
      isSelected (el) {
         return Alpine.$data(el).selected;
      },
      toggle (el) {
         this.isSelected(el) ? this.deselect(el) : this.select(el);
      },
      select (el) {
         this.selectable.select(el);
      },
      //# deselect
      deselect (el) {
         this.selectable.deselect(el);
      },
      //      # addSelected
      toGroup (groupId = null, trigger = null) {
         var media = this.selectedIds;
         groupId ??= this.groupId;
         trigger ??= 'add-edit.group-container';
         Livewire.emitTo(trigger, 'mediaAdded', media, groupId);
         this.destroy();
      },
      //# deleteSelected
      delete () {
         if (!confirm('This will delete all selected items.  Proceed?')) {
            return;
         }
         this.selected.forEach(el => {
            var data = Alpine.$data(el);
            this.deselect(el);
            setTimeout(() => {
               data.trash(false, data.mediaId);
            }, 350);
         });
         this.destroy();
      },
      selectable: null,
      selecting: false,
      selected: [],
      selectedIds: [],
      groupId: null,
      location: null
   });
}
