// plugins
import Splide from '@splidejs/splide';
import AlpineEditable from 'alpine-editable';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import masonry from 'alpinejs-masonry';
import { abandon, stripQuotes } from './alpine/_abandon';
import anchor from '@alpinejs/anchor';
// main
import Alpine from 'alpinejs';

// stores
import stores from './alpine/_stores';

window.Splide = Splide;

stores();
Alpine.plugin(abandon);
Alpine.plugin(stripQuotes);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(masonry);
Alpine.plugin(anchor);
Alpine.plugin(AlpineEditable);

window.Alpine ?? (window.Alpine = Alpine).start();
