import Alpine from 'alpinejs';
export default function () {
   Alpine.store('utils', {
      log (data) {
         let dt = new Date();
         console.log(dt.toLocaleTimeString() + ':' + data);
      },
      formatBytes: (bytes, decimals = 2) => {
         if (bytes === 0) return '0 Bytes';
         const k = 1024;
         const dm = decimals < 0 ? 0 : decimals;
         const sizes = ['Bytes', 'KB', 'MB', 'GB'];
         const i = Math.floor(Math.log(bytes) / Math.log(k));
         return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
         );
      }
   });
}
