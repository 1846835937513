import Alpine from 'alpinejs';

export default function () {
   Alpine.store('sidebar', {
      active: false,
      groupId: null,
      view: null,
      trigger: null,
      close () {
         this.active = false;
         this.groupId = null;
         this.view = null;
         Alpine.store('state')._sidebar(this.active, this.view);
      },
      open (args) {
         this.active = true;
         this.view = args?.view;
         this.groupId = args?.groupId;
         this.trigger = args?.trigger;
         if (this.view === 'all-media') {
            Alpine.store('selectable').activate({ location: 'sidebar' });
         }
         Alpine.store('state')._sidebar(this.active, this.view);
      },
      toggle () {
         if (this.active) {
            return this.close();
         }
         return this.open(arguments);
      }
   });
}
