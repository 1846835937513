import Alpine from 'alpinejs';

export default function () {
   Alpine.store('slider', {
      options: {
         perPage: 1,
         arrows: true,
         pagination: false,
         heightRation: 0.5625,
         classes: {
            pagination: 'splide__pagination group-pagination', // container
            page: 'splide__pagination__page group-page-indicator' // each button
         }
      },
      active (e) {
         let target = e.slide.querySelector('.x-media-item');
         Alpine.$data(target).autoplay = false;
         Alpine.$data(target).playing = true;
      },
      inactive (e) {
         let target = e.slide.querySelector('.x-media-item');
         Alpine.$data(target).autoplay = false;
         Alpine.$data(target).playing = false;
      }
   });
}
